<template>
    <div class="mb-60 baojia-box liucheng-top rounded-lg">
    <v-card class="pa-4" flat>
        <div class="text-center d-flex flex-column align-center">
            <div>
                <v-img width="300" src="@/assets/images/other/404@2x.png"> </v-img>
            </div>
            <p class="text-body-1 font-weight-bold">{{$t('网页出错了~')}}</p>
        </div>
    </v-card>
    </div>
</template>
<script>
export default {
    name:"Error",
    data(){
        return{

        }
    },
    mounted(){

    },
    methods:{

    }
}
</script>
<style scoped>

</style>